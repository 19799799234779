import React from "react"
import { graphql } from "gatsby"

import "katex/dist/katex.min.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./post.module.css"

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <>
      <SEO title={post.frontmatter.title} />
      <Layout>
        <div className={styles.entry}>
          <h1 className={styles.entryTitle}>{post.frontmatter.title}</h1>
          <p className={styles.entryMeta}>
            <time className={styles.entryDate} dateTime={post.frontmatter.date}>
              {post.frontmatter.date}
            </time>
            <ul className={styles.entryCategoryList}>
              <li className={styles.entryCategoryListItem}>
                {post.frontmatter.category}
              </li>
            </ul>
          </p>
          <div className={styles.entryBody} dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        category
        date
        title
      }
    }
  }
`
